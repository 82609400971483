import { baseConfig } from "@/utils/config";

// 图片格式
export const imagesExtension = [".png", ".jpg", ".jpeg", ".gif"];

// 不同文件类型的预览图
export const filePreviewImage = [
    {
        extension: [".pdf"],
        imgSrc: require("./image/pdf.png"),
    },
    {
        extension: [".doc", ".docx"],
        imgSrc: require("./image/doc.png"),
    },
    {
        extension: [".xlsx", ".xls"],
        imgSrc: require("./image/excel.png"),
    },
];

/**
 *  @author miaoFei
 *  @param uuid 文件uuid，根据uuid获取图片
 *  @return string
 *  @desc 回显图片，拼成完整url,可以在img标签下使用,完整的图片路径（相对服务器地址）
 *  @createTime：2022-01-16
 */
export function createFullImageUrl(uuid) {
    return `${ baseConfig.baseURL }applet/rent/files?uuid=${ uuid }`;
}

/**
 *  @author：miaoFei
 *  @param：uuidList: uuid[],uuid的数组集合
 *  @param：name 文件名称
 *  @param uuid 文件uuid，根据uuid获取图片
 *  @return：fileList: FileItem[]
 *  @desc：回显的文件，处理成在UploadImage中能够回显的规范的格式
 *  @createTime：2022-01-16
 */
export function createFormatUploadImages(uuidList = []) {
    return uuidList.map(uuid => {
        return {
            name: uuid,
            url: createFullImageUrl(uuid)
        };
    });
}

/**
 *  @author：miaoFei
 *  @param：file: 文件，{ name }
 *  @return：extension 后缀； fileName 文件名
 *  @desc：上传文件格式和名称
 *  @createTime：2022-04-14
 */
export function createFileNameExtension(file) {
    const name = typeof file === "string" ? file : file.name;
    const extensionIndex = name.lastIndexOf(".");       // 后缀序列
    const extension = name.substring(extensionIndex);   // 文件后缀
    const fileName = name.substring(0, extensionIndex);  // 文件名称
    return { extension, fileName };
}

/**
 *  @author：miaoFei
 *  @param：url: 文件链接；fileName：文件名称
 *  @desc：下载文件
 *  @createTime：2022-04-14
 */
export function downloadUploadFile({ url, fileName = new Date().getTime() + "" }) {
    const eLink = document.createElement("a");
    eLink.style.display = "none";
    eLink.href = url;
    eLink.setAttribute("download", fileName); // 文件名称
    eLink.setAttribute("target", "_blank"); // 新开窗口
    document.body.appendChild(eLink);
    eLink.click();
    URL.revokeObjectURL(eLink.href); // 释放URL 对象
    document.body.removeChild(eLink);
}
